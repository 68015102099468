<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Select2 name="vehicle_id" v-model.number="vehicle_id" :options="vehicleOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <TimeComponent :label="'From Time'" :selected_time="from_time" v-on:selected-time="setStart"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" type="date" v-model="to_date">
            </div>
            <TimeComponent :label="'To Time'" :selected_time="to_time" v-on:selected-time="setEnd"/>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12">
        <Datatable v-bind:entries="checkpoint_vehicle" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import TimeComponent from '../components/TimeComponent.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('checkpoint_vehicles', ['checkpoint_vehicle', 'onRequest']),
        title(){
          if(this.vehicle_id !== ''){
            let veh = this.vehicles.filter(item => item.id === this.vehicle_id)
            if(veh.length > 0){
              return 'Checkpoint Vehicle - ' + veh[0].vehicle_no + ' - ' + this.from_date + ' ' + this.from_time +  ' - ' + this.to_date + ' ' + this.to_time
            }
          }
          return 'Checkpoint Vehicle'
        }
    },
    data(){
      return {
        vehicle_id: '',
        account_id: '',
        from_date: moment().format("YYYY-MM-DD"),
        from_time: '00:00',
        to_date: moment().format("YYYY-MM-DD"),
        to_time: '23:59',
        columns: [
          {name: 'created', text: 'Time', order: 'desc', raw: {type:'Moment_UTC'}},
          {name: 'stop_name', text: 'Stop Name'},
          {name: 'is_arrival', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: true, text: 'Arriving'},
                {value: false, text: 'Leaving'},
              ]
            }
          },
          {name: 'next_stop_name', text: 'Next Stop'},
          {name: 'next_stop_arrived_eta', text: 'ETA', raw: {type:'Moment_UTC'}},
        ],
        vehicleOptions: [],
        accountOptions: [],
      }
    },
    components: {
      Datatable,
      TimeComponent,
      Select2,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {vehicle_id, from_date, from_time, to_date, to_time} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        // if(day < -3){
        //   this.error('Maximum Date Range is 3 days')
        //   return;
        // }

        this.get_data({vehicle_id, from_date, from_time, to_date, to_time})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getRoutes: 'get_vehicle_by_account'}),
      ...mapActions('checkpoint_vehicles', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.vehicle_id = ''
      },
      setStart(val){
        this.from_time = val
      },
      setEnd(val){
        this.to_time = val
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Checkpoint Vehicle')
      this.clear_data()
      this.get_all()
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
