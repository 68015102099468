<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="route_id">Route:</label>
              <Select2 name="route_id" v-model.number="route_id" :options="routeOptions" @change="onChangeRoute($event)" @select="onChangeRoute($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Select2 name="vehicle_id" v-model.number="vehicle_id" :options="vehicleOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="from_date">Date:</label>
              <input class="form-control" required type="date" v-model="from_date" :max="max" :min="min">
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
              <button v-if="details.length > 0" class="btn btn-warning ml-1" @click="clickAdjust()" :disabled="this.onRequest">Adjust</button>
            </div>
          </div>
        </form>
      </div><div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="trip_no">Trip No:</label>
            <input class="form-control" name="trip_no" type="number" v-model.number="trip_no">
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="distance">Distance:</label>
            <input class="form-control" name="distance" type="number" v-model.number="distance">
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="duration">Duration:</label>
            <input class="form-control" name="duration" type="number" v-model.number="duration">
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Trip No</th>
                  <th>Distance (km)</th>
                  <th>Duration (sec)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in details" :key="index">
                <td>{{item.trip_no}}</td>
                <td>{{item.distance}}</td>
                <td>{{item.duration}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('routes', ['routes']),
        ...mapState('adjust_geofence_summary_kms', ['adjust_geofence_summary_kms', 'onRequest']),
        title(){
          return 'Adjust Geofence Summary KM'
        },
    },
    data(){
      return {
        route_id: null,
        account_id: null,
        vehicle_id: null,
        from_date: moment().format("YYYY-MM-DD"),
        accountOptions: [],
        routeOptions: [],
        vehicleOptions: [],
        details: [],
        max: moment().format('YYYY-MM-DD'),
        min: "2023-05-01",
        isEdit: false,
        editIndex: null,
        trip_no: null,
        distance: null,
        duration: null,
      }
    },
    components: {
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {from_date, route_id, account_id, vehicle_id} = this
        this.get_geofence_summary_km({route_id: Number(route_id), account_id: Number(account_id), vehicle_id, date: from_date})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_route'}),
      ...mapActions('adjust_geofence_summary_kms', ['adjust_geofence_summary_km', 'get_geofence_summary_km']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.account_id = event.id
        this.route_id = null
        this.vehicle_id = null
      },
      onChangeRoute(event){
        this.getVehicles({route_id: parseInt(event.id)})
        this.route_id = event.id
        this.vehicle_id = null
      },
      clickAdjust(){
        if(this.onRequest) return;
        this.clear()
        const {from_date, route_id, account_id, vehicle_id, details} = this
        this.adjust_geofence_summary_km({details, route_id: Number(route_id), account_id: Number(account_id), vehicle_id, date: from_date})
      },
      clickAdd(){
        if(this.duration == null || this.distance == null || this.trip_no == null){
          return;
        }
        if(isNaN(this.duration) || isNaN(this.distance) || isNaN(this.trip_no)){
          return
        }
        let obj = {
          trip_no: this.trip_no,
          duration: this.duration,
          distance: this.distance,
        }
        if(this.isEdit){
          this.details[this.editIndex] = obj
        } else {
          this.details.push(obj)
        }
        this.resetData()
      },
      clickEdit(event, index){
        event.preventDefault()
        const {duration, distance, trip_no} = this.details[index]
        this.trip_no = trip_no
        this.distance = distance
        this.duration = duration
        this.editIndex = index
        this.isEdit = true
      },
      clickRemove(event, index){
        event.preventDefault()
        this.details.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit = false
        this.editIndex= null
        this.duration= null
        this.distance= null
        this.trip_no= null
      },
    },
    created() {
      this.$emit('onChildInit', 'Adjust Geofence Summary KM')
      this.get_all()
    },
    watch: {
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name +' - '+item.route_code})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      adjust_geofence_summary_kms(nextState, prevState){
        if(nextState !== prevState){
          this.details = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.details.push({duration:item.duration, distance:item.distance, trip_no: item.trip_no})
            })
          }
          return
        }
      },
    }
  }
</script>